// 引入封装的axios请求文件
import req from '../utils/request.js'

// 获取列表
export function productList (params) {
  return req.post('productList/v2', params)
}

// 批量删除
export function productBatchDelete (params) {
  return req.post('productBatchDelete', params)
}

// 删除单个
export function productDelete (params) {
  return req.post('productDelete', params)
}

// 添加product
export function productAdd (params) {
  return req.post('productAdd', params)
}

// 获取product回填详情
export function productDetail (params) {
  return req.post('productDetail', params)
}

// 修改product 可用不可用
export function productUpdateColumn (params) {
  return req.post('productUpdateColumn', params)
}

// 修改product
export function productEdit (params) {
  return req.post('productEdit', params)
}

// 获取最近上传的product
export function getLastFiveProductImport (params) {
  return req.post('getLastFiveProductImport', params)
}

// 上传product
export function productImport (params) {
  return req.post('productImport', params)
}

// 上传product详情
export function productImportDetail (params) {
  return req.post('productImportDetail', params)
}
