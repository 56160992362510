<template>
  <div class="import-product">
    <div class="import-detail">
      <div class="detail-top">
        <div class="files">
          <span class="files-name">OrderSampleImperialV1.0</span>
          <a-divider direction="vertical" />
          <span class="files-time">Imported At: 10:28:03 05-05-2022 </span>
        </div>
        <div>
          <a-space>
            <a-button shape="round">
              <a style="text-decoration:none;" :href="importDetail.val.upload_filename_url + '?' + Math.random()"
                :download="importDetail.val.upload_filename">
                Download File
              </a>
            </a-button>
            <a-button type="outline" status="danger" shape="round" :disabled="importDetail.val.error_products === 0">
              <a style="text-decoration:none;" :href="adslink + importDetail.val.error_filename_url + '?' + Math.random()"
                :download="importDetail.val.error_filename_name" v-if="importDetail.valerror_products !== 0">
                Error Product
              </a>
              <span v-else>Error Product</span>
            </a-button>
          </a-space>
        </div>
      </div>
      <div class="table-data">
        <div class="table-top">
          <div class="til">Import Products</div>
          <div class="count">
            <div>
              <span>Total</span>
              <span>{{ importDetail.val.include_products }}</span>
            </div>
            <div>
              <span>Imported</span>
              <span>{{ importDetail.val.success_products }}</span>
            </div>
            <div>
              <span class="error">Error</span>
              <span class="error">{{ importDetail.val.error_products }}</span>
            </div>
          </div>
        </div>
        <a-config-provider :locale="enUS">
          <a-table :columns="listColumns" :data="listData.value" style="margin-top: 24px" :pagination="false"
            :scroll="{ y: tableHeight.value }" :bordered="false" :row-selection="rowSelection" @select-all="selsectAll"
            @select="selectVal" :selected-keys="selectArr.val" :loading="loading">
            <template #empty>
              <div v-if="listData.value.length === 0 && loading === false"
                :style="{ height: tableHeight.value - 35 + 'px' }" class="empty-cont">
                <img src="../../../assets/images/empty/listempty.svg" alt="">
                <div>No results found</div>
              </div>
              <div v-if="loading === true" :style="{ height: tableHeight.value - 35 + 'px' }">
              </div>
            </template>
            <template #avaliable="{ record }">
              <a-switch checked-color="#13B966" unchecked-color="#F2F3F5" v-model="record.avaliable" :checked-value="1"
                :unchecked-value="2" @change="setAvalible(record.key, record.avaliable)">
                <template #checked> ON </template>
                <template #unchecked> OFF </template>
              </a-switch>
            </template>
            <template #action="{ record }">
              <a-tooltip content="Edit">
                <a-button shape="circle" @click="goEdit(record.key)">
                  <icon-edit />
                </a-button>
              </a-tooltip>
            </template>
          </a-table>
        </a-config-provider>
        <div class="page">
          <a-space>
            <span>Total : {{ importTotal }}</span>
            <a-button :disabled="currentpage == 1" @click="clickMinus" type="text">
              <icon-left />
            </a-button>
            <span class="currentpage">{{ currentpage }}</span>
            <div>/</div>
            <div>{{ totalPages }}</div>
            <a-button :disabled="currentpage == totalPages || totalPages == 0" @click="clickAdd" type="text">
              <icon-right />
            </a-button>
            <a-select :style="{ width: '134px' }" v-model="pageSize" @change="selectPage">
              <a-option :value="20">20 / Page</a-option>
              <a-option :value="50">50 / Page</a-option>
              <a-option :value="100">100 / Page</a-option>
              <a-option :value="200">200 / Page</a-option>
            </a-select>
          </a-space>
        </div>
      </div>
    </div>
    <div class="bottom-footer" v-if="selectArr.val.length > 0">
      <a-space>
        <a-checkbox v-model="checkedAll" @change="selsectAll" class="itemNum">
          {{ selectArr.val.length }} items
        </a-checkbox>
        <a-popconfirm content="Are you sure you want to delete?" position="top" @ok="deleteAll" ok-text="Delete"
          cancel-text="Cancel" class="deletePop" type="warning">
          <a-button status="danger" shape="round" v-if="isDisable === false && selectArr.val.length > 0">Delte
          </a-button>
        </a-popconfirm>
      </a-space>
    </div>
  </div>
</template>
<script setup>
import { useRouter, useRoute } from 'vue-router'
import { ref, reactive, onMounted, onBeforeMount } from 'vue';
import { productList, productBatchDelete, productUpdateColumn, productImportDetail } from '@/api/product.js'
import { Notification } from '@arco-design/web-vue'
import enUS from '@arco-design/web-vue/es/locale/lang/en-us'
import {
  IconRight, IconLeft, IconEdit
} from '@arco-design/web-vue/es/icon'
const adslink = process.env.VUE_APP_ADSLINK
const loading = ref(false)
const router = useRouter()
const route = useRoute()
let uploadId = route.query.upload
const rowSelection = {
  type: 'checkbox',
  showCheckedAll: true
}
// 获取窗口的高度
const tableHeight = reactive({ value: 0 })
tableHeight.value = document.body.clientHeight - 414
onMounted(() => {
  // 改变窗口高度
  window.onresize = () => {
    tableHeight.value = document.body.clientHeight - 414
  }
})
const listColumns = [
  {
    title: 'Avaliable',
    dataIndex: 'avaliable',
    slotName: 'avaliable',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'SKU',
    dataIndex: 'sku',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'Name',
    dataIndex: 'name',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'Description',
    dataIndex: 'description',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'Dimension(in)',
    dataIndex: 'dimension',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'Weight(lb)',
    dataIndex: 'weight',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'Declared Value',
    dataIndex: 'insurance',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'Action',
    dataIndex: 'action',
    slotName: 'action',
    width: 150
  }
]
// 当前页
const currentpage = ref(1)
// 表格数据
let listData = reactive({ value: [] })
// 初次渲染表格
onBeforeMount(() => {
  getList()
})
const importDetail = reactive({ val: {} })
// 获取上传详情
const getUploadDetail = async () => {
  const msg = await productImportDetail({
    upload_id: uploadId
  })
  if (msg.code === 0) {
    importDetail.val = msg.data
  }
}
getUploadDetail()
const importTotal = ref(0)
const totalPages = ref(0)
const pageSize = ref(20)
// 获取表格数据
const getList = async () => {
  loading.value = true
  const msg = await productList({
    page: currentpage.value,
    searchColumn: '',
    searchItem: '',
    sort: '',
    upload_id: uploadId,
    pageCount: pageSize.value
  })
  if (msg.code === 0) {
    loading.value = false
    let info = msg.data.data
    importTotal.value = msg.data.total
    currentpage.value = msg.data.current_page
    totalPages.value = msg.data.page_count
    let currentData = []
    for (let i = 0; i < info.length; i++) {
      currentData.push({ key: info[i].id, avaliable: info[i].status, sku: info[i].sku, name: info[i].name, description: info[i].description, dimension: info[i].length + 'x' + info[i].width + 'x' + info[i].height, weight: info[i].weight, insurance: info[i].insurance })
    }
    listData.value = currentData
  } else {
    loading.value = false
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
let isDisable = ref(true)
let selectArr = reactive({
  val: []
})
const selsectAll = (checked) => {
  if (checked) {
    checkedAll.value = true
    selectArr.val = []
    listData.value.forEach((item) => {
      selectArr.val.push(item.key)
      isDisable.value = false
    })
  } else {
    checkedAll.value = false
    selectArr.val = []
    isDisable.value = true
  }
}
function selectVal (rowKeys) {
  selectArr.val = rowKeys
  if (rowKeys.length > 0) {
    isDisable.value = false
  } else {
    isDisable.value = true
  }
}
// 去修改
const goEdit = (editId) => {
  router.push('/products/editproducts?editId=' + editId)
}
// 批量删除
const deleteAll = async () => {
  const msg = await productBatchDelete({
    ids: selectArr.val
  })
  if (msg.code === 0) {
    Notification.success({
      title: 'Success',
      content: 'Delete successful.'
    })
    currentpage.value = 1
    listData.value = []
    getList()
  } else {
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
// 设置可用不可用
const setAvalible = async (val, isAvalible) => {
  const msg = await productUpdateColumn({
    id: val,
    column: 'status',
    value: isAvalible
  })
  if (msg.code === 0) {
    Notification.success({
      title: 'Success',
      content: 'Set successfully.'
    })
  } else {
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
const checkedAll = ref(false)
// 点击页数减
const clickMinus = () => {
  currentpage.value--
  getList()
}
// 点击页数加
const clickAdd = () => {
  currentpage.value++
  getList()
}
const selectPage = (val) => {
  pageSize.value = val
  getList()
}
</script>

<style lang="less" scoped>
.empty-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
    color: rgba(28, 31, 35, 0.8);
    font-weight: 400;
    font-size: 14px;
    padding-top: 30px;
  }
}

.import-product {
  position: relative;
}

.import-detail {
  margin: 0 24px;
}

.detail-top {
  margin: 18px 24px 20px 24px;
  display: flex;
  justify-content: space-between;

  .files-name {
    font-weight: 500;
    font-size: 20px;
    color: var(--color-text-1);
  }

  .files-time {
    font-weight: 400;
    font-size: 14px;
    color: var(--color-text-3);
  }
}

.til {
  font-weight: 500;
  font-size: 16px;
  color: var(--color-text-1);
}

.table-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.count {
  width: 235px;
  border: 1px solid var(--color-border-2);
  border-radius: 4px;

  div {
    height: 26px;
    line-height: 26px;

    span:first-child {
      display: inline-block;
      padding-left: 20px;
      width: 120px;
      border-right: 1px solid var(--color-border-2);
      background: var(--color-fill-1);
      color: var(--color-text-3);
      font-weight: 500;
      font-size: 12px;
    }

    span:nth-child(2) {
      padding-left: 20px;
      font-weight: 400;
      font-size: 12px;
      color: var(--color-text-1);
    }
  }
}

.error {
  color: rgb(var(--danger-6)) !important;
}

.count div:not(:last-child) {
  border-bottom: 1px solid var(--color-border-2);
}

.bottom-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 72px;
  padding-left: 20px;
  border-top: 1px solid var(--color-border-2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background-color: #fff;
  z-index: 999;
}

.page {
  display: flex;
  justify-content: flex-end;
  padding-top: 24px;

  .arco-btn-text.arco-btn-disabled,
  .arco-btn-text[type='button'].arco-btn-disabled {
    color: var(--color-text-4);
  }

  .arco-btn-text,
  .arco-btn-text[type='button'] {
    color: var(--color-text-2);
  }
}

.currentpage {
  color: rgb(var(--primary-6));
  background-color: var(--color-primary-light-1);
  padding: 1px 8px;
  display: inline-block;
  box-sizing: border-box;
}
</style>
<style>
.deletePop .arco-popconfirm-popup-content .arco-popconfirm-footer .arco-btn-primary {
  color: rgb(var(--danger-6));
  background-color: rgb(var(--danger-1));
}
</style>
